import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/form',
    name: 'formulario',
    component: () => import('../views/FormularioVentasView.vue'),
    meta: {
      needsAuth: true
    }
  },
  {
    path:'/ventas',
    name: 'ventas',
    component: () => import('../views/VentasView.vue'),
    meta: {
      needsAuth: true
    }
  },
  {
    path:'/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path:'/misventas',
    name: 'misventas',
    component: () => import('../views/VentasProveedor.vue')
  },

]

const isUserLoggedIn = () => {
  const token = localStorage.getItem('token')

  if(token) return true;
  return false
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{
  if (to.meta.needsAuth){
    if (isUserLoggedIn()){
      next();
    }
    else{
      next("/");
    }
  } else{
    next();
  }
} )

export default router
