<template>
  <v-app>
    <header-app v-if="!isLoginPage"></header-app>
    <router-view/>
  </v-app>
</template>
<script>
import HeaderApp from './components/HeaderApp.vue';
export default{
  components: { HeaderApp },
  data(){
    return{
      isLoginPage: false
    }
  },
  watch:{
    '$route' (to, from){
      this.isLoginPage = to.name === 'login'
    }
  },
  mounted(){
    this.deletToken()
  },
  methods:{
    deletToken(){
      setInterval(()=>{
        localStorage.removeItem("token");
      },1800000 )
    },

  }
}

</script>
<style>
</style>
