import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

const BASE_URL = 'https://apiventas.fibwi.pe/api/ventas/';
// const BASE_URL = 'http://127.0.0.1:8000/api/ventas/';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tipo_docs: [],
    sedes: [],
    scorings: [],
    qlobbi: [],
    tarifas: [],
    proveedores:[],
    vendedores: [],
    ventas: [],
    venta:[],
    adjuntos: [],
    estados:[],
    id_venta_created: 0,
    id_estado_venta: 0,
  },
  getters: {
  },
  mutations: {
    SET_TIPODOCS: (state, tipo_docs) => state.tipo_docs = tipo_docs,
    SET_SEDES: (state, sedes) => state.sedes = sedes,
    SET_SCORINGS: (state, scorings) => state.scorings = scorings,
    SET_QLOBBI: (state, qlobbi) => state.qlobbi = qlobbi,
    SET_PROVEEDOR: (state, proveedores) => state.proveedores = proveedores,
    SET_VENDEDOR: (state, vendedores) => state.vendedores = vendedores,
    SET_PRODUCTOS: (state, tarifas) => state.tarifas = tarifas,
    SET_VENTAS: (state, ventas) => state.ventas = ventas,
    SET_VENTA: (state, venta) => state.venta = venta,
    SET_ADJUNTOS: (state, adjuntos) => state.adjuntos = adjuntos,
    SET_ESTADOS: (state, estados) => state.estados = estados,
    SET_ID_VENTA: (state, id_venta) => state.id_venta_created = id_venta,
    UPDATE_ESTADO_VENTA: (state, id_estado_venta) => state.id_estado_venta = id_estado_venta
  },
  actions: {
    ///////////////////////////////////
    ////////// OBTENER DATOS //////////
    ///////////////////////////////////
    async fetchTipoDoc({commit}, url = BASE_URL){
      let url_c = url+ 'tipo_doc/'
      const response = await axios.get(url_c)
      commit('SET_TIPODOCS', response.data)
    },
    async fetchSede({commit}, url= BASE_URL){
      let url_c = url+'sedes/'
      const response = await axios.get(url_c)
      commit('SET_SEDES', response.data)
    },
    async fetchScoring({commit}, url= BASE_URL){
      let url_c = url+'scorings/'
      const response = await axios.get(url_c)
      commit('SET_SCORINGS', response.data)
    },
    async fetchQlobbi({commit}, url= BASE_URL){
      let url_c = url+'qlobbis/'
      const response = await axios.get(url_c)
      commit('SET_QLOBBI', response.data)
    },
    async fetchProveedor({commit}){
      let url_c = BASE_URL+'proveedores/'
      const response = await axios.get(url_c)
      commit('SET_PROVEEDOR', response.data)
    },
    async fetchVendedor({commit}, id_proveedor=1){
      let url_c = BASE_URL+'vendedores/?id_proveedor='+id_proveedor
      const response = await axios.get(url_c)
      commit('SET_VENDEDOR', response.data)
    },
    async fetchProducto({commit}, url= BASE_URL){
      let url_c = url+'productos/'
      const response = await axios.get(url_c)
      commit('SET_PRODUCTOS', response.data)
    },
    async fetchVentas({commit}, url= BASE_URL){
      let url_c = url+'ventas/'
      const response = await axios.get(url_c)
      commit('SET_VENTAS', response.data)
    },
    async fetchVenta({commit}, params){
      let url_c = BASE_URL+'venta/'+params.id_venta
      const response = await axios.get(url_c)
      commit('SET_VENTA', response.data)
      return response.data
    },
    async fetchVentaProveedor({commit}, params){
      let url_c = BASE_URL+'ventas_proveedor/'+params.identificador
      const response = await axios.get(url_c)
      commit('SET_VENTAS', response.data)
      return response.data
    },
    async fetchAdjuntos({commit}, params){
      let url_c = BASE_URL+'adjuntos/?id_venta='+params.id_venta
      const response = await axios.get(url_c)
      commit('SET_ADJUNTOS', response.data)
    },
    async fetchEstados({commit}, params){
      let url_c = BASE_URL+'estado_by_tipo?tipo_estado='+params.id_tipo_estado
      const response = await axios.get(url_c)
      commit('SET_ESTADOS', response.data)
    },
    ///////////////////////////////////
    ////////// ENVIAR DATOS ///////////
    ///////////////////////////////////
    async sendVentas({commit}, params={}){
      let url_c = BASE_URL+'venta/'
      const response = await axios.post(url_c, params)
      const id_venta = response.data.id_ventas
      commit('SET_ID_VENTA', id_venta)
      return id_venta
    },
    async sendAdjuntos({commit}, params = {}){
      let url_c = BASE_URL+'adjunto/?id_venta='+params.id_venta+'&dni_cliente='+params.dni_cliente+'&tipo_adjunto='+params.tipo_adjunto
      await axios.post(url_c, params.files,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      let url_a = BASE_URL+'adjuntos/?id_venta='+params.id_venta
      const response = await axios.get(url_a)
      commit('SET_ADJUNTOS', response.data)
    },
    async updateEstadoVenta({commit}, params = {}){
      let url_c = BASE_URL+'estado_venta/'+params.id_venta+'?id_estado='+params.id_estado
      await axios.put(url_c)
      let url_a = BASE_URL+'ventas/'
      const response = await axios.get(url_a)
      commit('SET_VENTAS', response.data)
    },
    async updateEstadoInstalacion({commit}, params = {}){
      let url_c = BASE_URL+'estado_instalacion/'+params.id_venta+'?id_estado='+params.id_estado
      await axios.put(url_c)
      let url_a = BASE_URL+'ventas/'
      const response = await axios.get(url_a)
      commit('SET_VENTAS', response.data)
    }
  },
  modules: {
  }
})
