<template>
  <div>
    <v-app-bar app color="white" dark height="80px" hide-on-scroll>
      <v-row justify="center">
        <v-col class="d-flex align-center">
          <v-img
            alt="Fibwi Logo"
            class="shrink mr-2"
            contain
            src="../assets/logo fibwi.png"
            transition="scale-transition"
            width="70px"
          />
        </v-col>
        <v-col class="d-flex align-end flex-column">
          <v-btn text fab @click="exit"><v-icon large color="red">mdi-exit-to-app</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  computed:{
    getUserLogged() {
      const id_usuario_scord = parseInt(localStorage.getItem("token"));
      return this.$store.getters.getUser(id_usuario_scord);
    },
  },
  methods: {
    exit() {
      let token = localStorage.getItem("token");
      if (token) {
        console.log("Credenciales Vencidas. Ingrese de nuevo");
        localStorage.removeItem("token");
        location.reload();
      } else {
        console.log("Usuario no logeado");
      }
    },
  },
};
</script>
<style>
</style>